 
<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />

        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="2" class="my-1">
      <b-button block variant="primary" size="sm"><feather-icon icon="PlusCircleIcon" class="mr-50" size='15' /> Add
        Pengajuan
      </b-button>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(aduan)="data">

          <b-row class="match-height">
            <b-col md="4">
              <h6><strong class="text-primary">Lokasi BPJ Cilacap, Kab. Cilacap</strong>
              </h6>
              <h6 class="text-primary"><small>Nama Ruas Jalan </small><br> <strong>CILOPADANG – BTS. KAB
                  BREBES/CILACAP</strong></h6>
              <b-row>
                <b-col md="4">
                  <b-button variant="outline-primary" block size="sm" v-b-modal.modal-1>
                    <feather-icon icon="GridIcon" size='15' /> Detail
                  </b-button>
                </b-col>
              </b-row>

            </b-col>

            <b-col md="4">
              <b-alert variant=" " show>
                <div class="alert-body">
                  <h5 class="text-primary"><small> Nama Pekerjaan </small>
                    <br>
                    <strong class="font-size:25px"> Perbaikan Jalan pada KM 40 </strong>
                  </h5>
                  <h6 class="text-primary"><small> Unit Kerja Perangkat Daerah </small>
                    <br><small>
                      <strong> BPJ Cilacap</strong>
                    </small>
                  </h6>
                  <b-row>
                    <b-col md="6" xs="6" sm="6">
                      <h6><small>Kabupaten/Kota</small><br><span class="text-primary">Kab. Cilacap</span> </h6>
                    </b-col>
                    <b-col md="6" xs="6" sm="6">
                      <h6><small>Jenis Pekerjaan <br><b class="text-primary">Rehabilitasi Jalan</b></small> </h6>
                    </b-col>
                  </b-row>
                </div>
              </b-alert>

            </b-col>

            <b-col md="4">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <b-row>
                    <b-col md="12">
                      <h6 class="text-primary"><small>Capaian Kinerja Program dan Kerangka Pendanaan</small></h6>
                      <h5 class="text-primary"><small>Tareget</small> <br>10 Kilo Meter </h5>
                    </b-col>
                    <b-col md="12">
                      <h5 class="text-primary"><small>Anggaran</small><br>
                        <span style="font-size: 30px;" class="mt-1"><sup>Rp.</sup> <strong>102.062.000,-</strong></span>
                      </h5>
                    </b-col>
                  </b-row>
                </div>
              </b-alert>

            </b-col>
          </b-row>
        </template>


      </b-table>
    </b-col>
    <b-col cols="6">
      Total Ajuan {{ items.length }}
    </b-col>
    <b-col cols="6">
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BAlert
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [15, 5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'aduan',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
      items: [
        {
          id: 1,
        },

      ],

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
  },
  methods: {

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
 