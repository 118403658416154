<template>
  <div>
    <b-card>
      <statistik></statistik>
    </b-card>

    <b-card>
      <b-row>
        <b-col md="12">
          <tabel_data></tabel_data>
        </b-col>
      </b-row>
      <detail-kegiatan></detail-kegiatan>
    </b-card>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import tabel_data from './component/tabel.vue'
import statistik from './component/statistik.vue'
import DetailKegiatan from './component/detailKegiatan.vue'
import {
  BTabs,
  BTab, BCard, BRow, BCol
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab, BCard, tabel_data, BRow, BCol, tabel_data, statistik,
    DetailKegiatan
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  methods: {
    async xxx() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>