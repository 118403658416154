<template>
  <div>
    <b-row>
      <b-col md="4">

        <h4 class="text-primary"> Kegatan Tambahan APBN <br>Tahun Anggaran 2024
        </h4>
      </b-col>
      <b-col md="5">
        <h5 class="text-center"><small><strong>Total Kegiatan</strong></small><br>
          <span style="font-size: 30px;" class="mt-1 text-primary text-center"><strong>1</strong></span>
        </h5>
      </b-col>
      <b-col md="3">
        <h5 class=""><small><strong>Total Anggaran</strong></small><br>
          <span style="font-size: 30px;" class="mt-1 text-primary"><sup>Rp.</sup> <strong>102.062.000,-</strong></span>
        </h5>
      </b-col>



    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BAvatar, BAlert
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BAvatar, BAlert
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  methods: {
    async xxx() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>