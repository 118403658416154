<template>
  <div>

    <b-modal id="modal-1" title="Detaik kegiatan" ok-disabled size="xl" hide-footer no-close-on-esc>
      <b-alert variant="warning" show>
        <div class="alert-body text-dark">
          <b-row>
            <b-col md="6">
              <h5><small><strong>Tujuan</strong></small><br>
                <small class="fz15"> Meningkatkan Kualitas Jalan Provinsi </small>
              </h5>
              <h5><small><strong>Indikator Tujuan</strong></small><br>
                <small class="fz15">Persentase jalan provinsi sesuai standar jalan kolektor dan kondisi permukaan mantap
                </small>
              </h5>
              <h5><small><strong>Sasaran</strong></small><br>
                <small class="fz15"> Meningkatnya Panjang Jalan Provinsi dengan Kualitas Struktur dan Kapasitas Sesuai
                  Standar
                  Jalan Kolektor </small>
              </h5>
              <h5> <small><strong>Indikator Sasaran </strong></small> <br>
                <small class="fz15">Persentase panjang jalan provinsi sesuai standar jalan kolektor </small>
              </h5>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="3"><strong>1.03.10</strong></b-col>
                <b-col md="9"><strong>PROGRAM PENYELENGGARAAN JALAN </strong><br><small>Indikator Program : Persentase
                    capaian
                    penyusunan
                    perencanaan jalan dan
                    jembatan</small></b-col>
                <b-col md="3"><strong>1.03.10.1.01</strong></b-col>
                <b-col md="9"><strong>Penyelenggaraan Jalan Provinsi</strong><br><small>Indikator kegiatan : Panjang Jalan
                    Provinsi yang Ditingkatkan Kondisinya Menuju Standar Jalan Kolektor di Bidang Pelaksana Jalan Wilayah
                    Barat</small></b-col>
                <b-col md="3"><strong>1.03.10.1.01.0030</strong></b-col>
                <b-col md="9"><strong>Rehabilitasi Jalan</strong><br><small>Indikatro Sub Kegiatan : Panjang Jalan yang
                    Direhabilitasi di Wilayah Barat (KM)</small></b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-alert>

      <hr>
      <b-alert variant="primary" show>
        <div class="alert-body text-dark">
          <h5 class="text-primary"> <strong>Rincian Kegiatan</strong> </h5>
          <b-row>
            <b-col md="6">
              <h5><small>Nama Pekerjaan</small><br><span class="fz15">Penyusunan Rencana, Kebijakan, Strategi dan Teknis
                  Pengembangan</span></h5>
              <h5><small>Jenis Pekerjaan</small><br><span class="fz15">Lainya</span></h5>
              <h5><small>Target Pekerjaan</small><br><strong>40 KM</strong></h5>
              <h5><small>Pagu Anggaran</small><br><strong><sup>Rp.</sup>102.062.000,-</strong></h5>
            </b-col>
            <b-col md="6">
              <h5>File Pendukung Kegiatan</h5>
              <b-row>
                <b-col col='6'>

                  <h5><b-avatar rounded="" variant="warning" size="25"><feather-icon icon="DownloadIcon"
                        size='15' /></b-avatar>
                    <span class="text-primary"> Proposal Kegiatan</span>
                  </h5>
                  <h5><b-avatar rounded="" variant="warning" size="25"><feather-icon icon="DownloadIcon"
                        size='15' /></b-avatar>
                    <span class="text-primary"> KAK Kegiatan</span>
                  </h5>

                </b-col>
                <b-col col='6'>


                  <h5><b-avatar rounded="" variant="warning" size="25"><feather-icon icon="DownloadIcon"
                        size='15' /></b-avatar>
                    <span class="text-primary"> RAB Kegiatan</span>
                  </h5>
                  <h5><b-avatar rounded="" variant="warning" size="25"><feather-icon icon="DownloadIcon"
                        size='15' /></b-avatar>
                    <span class="text-primary"> DED Kegiatan</span>
                  </h5>
                </b-col>
              </b-row>

            </b-col>
          </b-row>
        </div>
      </b-alert>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BAvatar, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BRow, BCol, BAvatar,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal-1', 'AddLevel')
    }
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style >
.fz15 {
  font-size: 14px
}
</style>